<div class="mat-drawer-backdrop" (click)="_onBackdropClicked()" *ngIf="hasBackdrop"
     [class.mat-drawer-shown]="_isShowingBackdrop()"></div>

<ng-content select="mat-drawer"></ng-content>

<ng-content select="mat-drawer-content">
</ng-content>
<mat-drawer-content *ngIf="!_content">
  <ng-content></ng-content>
</mat-drawer-content>
