import {Observable, catchError, timeout, throwError as observableThrowError, finalize} from 'rxjs';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthService} from '../auth/auth.service';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {HIDE_GLOBAL_PROGRESS_BAR, SHOW_GLOBAL_PROGRESS_BAR} from '../store/layout/progress-bar/progress-bar';

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
    private readonly DEFAULT_TIMEOUT = 60 * 1000;

    constructor(public auth: AuthService, private store$: Store<any>) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.store$.dispatch({type: SHOW_GLOBAL_PROGRESS_BAR});
        const timeoutValue = request.headers.get('timeout') || this.DEFAULT_TIMEOUT;

        return next.handle(request).pipe(
            timeout(+timeoutValue),
            catchError((error) => {
                if (error.name === 'TimeoutError') {
                    console.error('Request Timeout');
                }
                return observableThrowError(() => error);
            }),
            finalize(() => {
                this.store$.dispatch({type: HIDE_GLOBAL_PROGRESS_BAR});
            })
        );
    }
}
