import {HttpClient, HttpParams} from '@angular/common/http';
import {Pagination} from '@shared/modules/list-table/models/pagination';
import * as _ from 'lodash';
import {ApiRouterService} from '@shared/services/api-router.service';
import {Inject} from '@angular/core';
import {APP_CONFIG} from '../../../../app-config/app-config.constants';
import {IAppConfig} from '../../../../app-config/app-config.interface';
import {LocalStorageService} from '@shared/services/local-storage.service';
import {LOCAL_STORAGE} from '@shared/constants/local-storage.constants';
import {CreateOptions} from '@shared/components/crud/create/types';
import {DeleteOptions} from '@shared/components/crud/delete/types';
import {UpdateOptions} from '@shared/components/crud/update/types';
import {RequestService} from "@shared/services/request.service";

export interface CrudGetAllServiceInterface {
    getAll(pagination: Pagination | null, filters: {}, sortings: {}, url: string, routeParams: {});
}

export interface CrudServiceInterface extends CrudGetAllServiceInterface {
    getOne(id: number);

    create(options: CreateOptions);

    getCreateFormConfigurations(options: CreateOptions);

    validateOnCreate(options: CreateOptions);

    update(options: UpdateOptions);

    getUpdateFormConfigurations(options: UpdateOptions);

    validateOnUpdate(options: UpdateOptions);

    delete(options: DeleteOptions);
}

export class CrudGetAllService implements CrudGetAllServiceInterface {
    private isGetAllFirstRequest = true;

    protected constructor(protected http: HttpClient,
                          @Inject(APP_CONFIG) protected config: IAppConfig,
                          protected defaultSorting = '-id'
    ) {
    }

    getAll(pagination: Pagination | null = null,
           filters                       = {},
           sortings                      = {},
           url: string                   = '',
           routeParams                   = {},
           forceFilters                  = false,
           additionalParams              = {},
           headers                       = {}
    ) {
        let params: HttpParams;

        if (this.isGetAllFirstRequest) {
            params = new HttpParams({
                fromString: window.location.search.slice(1),
                encoder:    RequestService.getHttpEncoder(),
            });

            if (!params.get('page')) {
                params = this.appendPaginationToParams(params, pagination);
            }
            if (!params.get('sort') && pagination) {
                params = this.appendSortingsToParams(params, sortings);
            }

            this.isGetAllFirstRequest = false;
        } else {
            params = new HttpParams({encoder: RequestService.getHttpEncoder()});
            params = this.appendPaginationToParams(params, pagination);

            if (pagination) {
                params = this.appendSortingsToParams(params, sortings);
            }
        }

        if (!this.isGetAllFirstRequest || forceFilters) {
            params = this.appendFiltersToParams(params, filters);
        }

        if (additionalParams) {
            params = this.appendAdditionalParams(params, additionalParams)
        }

        this.setUrlParams(params);
        return this.http.get(ApiRouterService.getAdminRoute(url, routeParams), {headers: headers, params: params});
    }

    protected appendAdditionalParams(params: HttpParams, additionalParams = {}) {
        for (const property in additionalParams) {
            if (additionalParams.hasOwnProperty(property)) {
                params = params.has(property)
                    ? params.set(property, additionalParams[property])
                    : params.append(property, additionalParams[property]);
            }
        }

        return params;
    }

    protected appendPaginationToParams(params: HttpParams, pagination: Pagination | null = null) {
        if (!pagination) {
            return params;
        }

        const limit = LocalStorageService.getDataForSpecificUrl(LOCAL_STORAGE.pageSize, pagination.perPage);

        params = params.append('page', pagination.currentPage + '');
        params = params.append('limit', limit + '');

        return params;
    }

    protected appendSortingsToParams(params: HttpParams, sortings: {}) {
        let sorting = '';

        sortings = LocalStorageService.getDataForSpecificUrl(LOCAL_STORAGE.sorting, sortings);
        if (sortings.hasOwnProperty('empty')) {
            return params;
        }

        if (!sortings.hasOwnProperty('active')) {
            return params.append('sort', this.defaultSorting);
        }

        if (sortings['active'] instanceof Object) {
            for (let columnName of sortings['active']) {
                const direction = sortings['direction'][columnName];
                columnName      = _.camelCase(columnName).replace('_', '');

                if (!direction) {
                    continue;
                }

                sorting += direction === 'desc' ? `-${columnName}` : columnName;
                sorting += ',';
            }
            sorting = sorting.slice(0, -1)
        } else {
            const columnName = _.camelCase(sortings['active']).replace('_', '');
            if (sortings['direction']) {
                sorting = sortings['direction'] === 'desc' ? `-${columnName}` : columnName;
            }
        }

        sorting = sorting.length ? sorting : this.defaultSorting;
        return params.append('sort', sorting);
    }

    protected appendFiltersToParams(params: HttpParams, filters: {}) {
        const filterNames = Object.keys(filters);

        if (!filterNames.length) {
            return params;
        }

        for (const filter of filterNames) {
            const constraints = Object.keys(filters[filter]);

            for (const constraint of constraints) {
                const value    = filters[filter][constraint];
                let filterName = '';

                if (Array.isArray(value)) {
                    value.forEach((subValue, index) => {
                        filterName = `filter[${filter}][${constraint}][${index}]`;
                        params     = params.delete(filterName).append(filterName, subValue);
                    });
                    continue;
                }

                filterName = `filter[${filter}][${constraint}]`;
                params     = params.delete(filterName).append(filterName, value);
            }
        }

        return params;
    }

    protected mapResponse(response: any) {
        return response.map(res => {
            // If request fails, throw an Error that will be caught
            if (res.status < 200 || res.status >= 300) {
                throw new Error('This request has failed ' + res.status);
            }
            // If everything went fine, return the response
            else {
                return res.json();
            }
        });
    }

    private setUrlParams(params: HttpParams) {
        const currentUrl   = window.location.href.split('?').shift();
        const paramsString = params.toString();

        if (paramsString) {
            window.history.replaceState({}, '', currentUrl + '?' + paramsString);
        }
    }
}

export abstract class CrudService extends CrudGetAllService implements CrudServiceInterface {

    abstract getOne(id: number);

    abstract create(options: CreateOptions);

    abstract getCreateFormConfigurations(options: CreateOptions);

    abstract validateOnCreate(options: CreateOptions);

    abstract update(options: UpdateOptions);

    abstract getUpdateFormConfigurations(options: UpdateOptions);

    abstract validateOnUpdate(options: UpdateOptions);

    abstract delete(options: DeleteOptions);

    protected constructor(protected http: HttpClient,
                          @Inject(APP_CONFIG) protected config: IAppConfig,
                          protected defaultSorting = '-id'
    ) {
        super(http, config, defaultSorting);
    }
}
