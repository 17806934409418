export const APP_PATH = {
    channelDataEventBus: 'channel-data/event-bus',
    channelDataSyncQueue: 'channel-data/sync-queue',

    languageAnalyzeEntity: 'language/analyze-entity',
    languageStopWords: 'language/stop-words',

    searchTermRelations: 'search-term/relations',
    searchTermTagEditorPerformanceTime: 'search-term/tag-editor-performance-time',

    advertisingDataExport: 'search-term/advertising-data-export',
    advertisingDataExportDetail: 'search-term/advertising-data-export/:id',

    kornit: 'kornit/general-settings',
    kornitMedia: 'kornit/media',
    kornitPrinters: 'kornit/printers',
    kornitSettings: 'kornit/settings',
    kornitMotiveSettings: 'kornit/motive-settings',
    kornitRawProductSettings: 'kornit/raw-product-settings',
    kornitRawProductSettingsCards: 'kornit/raw-product/:rawProductId/printer-type/:printerType',
    kornitRawProductColorReferenceSettings: 'kornit/raw-product/:rawProductId/printer-type/:printerType/colors',
    kornitMeasureSettings: 'kornit/raw-product/:rawProductId/printer-type/:printerType/measures',

    shipmanOrderItems: 'shipman/order-items',
    shipmanOpenRoqQueues: 'shipman/open-roq-queues',
    shipmanManifest: 'shipman/manifest',

    amazonShippingGroups: 'amazon/shipping-groups',
    amazonBlockedProductVariations: 'amazon/blocked-product-variations',

    amazonProductTypes: 'amazon/product-types',

    feedQueues: 'amazon/feed-queues',

    feedTestToolShopProducts: 'amazon/feed-test-tool',
    feedTestToolXmlEditor: 'amazon/feed-test-tool/xml-editor',

    vineProducts: 'amazon/vine-products',
    vineOrders: 'amazon/vine-orders',

    axSemanticCollections: 'ax-semantic/collections',
    axSemanticEvents: 'ax-semantic/events',
    axSemanticBuyerGroups: 'ax-semantic/buyer-groups',
    axSemanticMotiveDescriptionAdjectives: 'ax-semantic/motive-description-adjectives',
    axSemanticMotiveDescriptionNouns: 'ax-semantic/motive-description-nouns',
    axSemanticTargetGroups: 'ax-semantic/target-groups',
    axSemanticThemes: 'ax-semantic/themes',
    axSemanticMotiveStyles: 'ax-semantic/motive-styles',

    productDetail: 'product/products/:id',

    fulfillmentProviderPrintingFileTemplates: 'raw-product/fulfillment-provider-printing-file-templates',

    rawProduct: 'raw-product/raw-product',
    rawProductEdit: 'raw-product/raw-product/:id/edit',

    orders: 'orders',
    notShippedOrders: 'not-shipped-orders',
    orderDetail: 'orders/:id',
    fulfillmentProviderOrders: 'fulfillment-provider-orders',
}
